:root {
  --yc-font-size: 12px;
  --yc-bg: #f8f9fe;
  --yc-text-color: #2f2f3b;
  --yc-text-color-lighter: #8f8f9b;
  --yc-layout-main-padding: 16px;
  --yc-font-family: "Averta", "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
  --yc-heading-color: #2f2f3b;
  --yc-card-border-radius: 15px;
  --yc-card-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}

@media (min-width: 600px) {
  :root {
    --yc-font-size: 13px;
    --yc-layout-main-padding: 1.5rem;
  }
}
@media (min-width: 960px) {
  :root {
    --yc-font-size: 14px;
    --yc-layout-main-padding: 1.5rem;
  }
}
@media (min-width: 1280px) {
  :root {
    --yc-font-size: 15px;
    --yc-layout-main-padding: 1.75rem;
  }
}
@media (min-width: 1920px) {
  :root {
    --yc-font-size: 16px;
    --yc-layout-main-padding: 2rem;
  }
}
* {
  font-family: var(--yc-font-family) !important;
}

body.hn * {
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-size: 2rem;
  margin-top: 1em;
}

h3 {
  font-weight: 600 !important;
  font-size: 1.33333rem !important;
  border-bottom: 1px solid var(--yc-h3-border-color);
  padding-bottom: 0.5em;
}

form h3 {
  border-bottom: none;
  margin: 0;
  padding-bottom: 0;
}

h5 {
  font-weight: 600 !important;
}

@font-face {
  font-family: "Averta";
  font-weight: 100;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-ExtraLight.woff2") format("woff2"), url("/assets/fonts/Averta-ExtraLight.otf") format("opentype"), url("/assets/fonts/Averta-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Averta";
  font-weight: 200;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-Light.woff2") format("woff2"), url("/assets/fonts/Averta-Ligth.otf") format("opentype"), url("/assets/fonts/Averta-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Averta";
  font-weight: 400;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-Regular.woff2") format("woff2"), url("/assets/fonts/Averta-Regular.otf") format("opentype"), url("/assets/fonts/Averta-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Averta";
  font-weight: 500;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-SemiBold.woff2") format("woff2"), url("/assets/fonts/Averta-SemiBold.otf") format("opentype"), url("/assets/fonts/Averta-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Averta";
  font-weight: 600;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-Bold.woff2") format("woff2"), url("/assets/fonts/Averta-Bold.otf") format("opentype"), url("/assets/fonts/Averta-Bold.ttf") format("truetype");
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  position: static;
  overflow-y: hidden;
  height: 100%;
  max-height: 100%;
}

html,
body {
  height: 100%;
  min-height: 100dvh;
  overscroll-behavior: none !important;
  font-size: var(--yc-font-size);
  font-weight: 300;
  background-color: var(-yc-bg);
}

body {
  margin: 0;
  padding: 0;
  color: var(--yc-text-color);
  background-color: var(--yc-bg);
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  user-select: none;
}

::-webkit-scrollbar {
  width: 6px;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: rgba(0, 0, 0, 0.1);
}

.spacer {
  flex: 1;
}