//---------------------------------------
//  Responsive Breakpoints Media Queries
//---------------------------------------
$breakpoints: (
  xs: (
    begin: 0,
    end: 599.9px,
  ),
  sm: (
    begin: 600px,
    end: 959.9px,
  ),
  md: (
    begin: 960px,
    end: 1279.9px,
  ),
  lg: (
    begin: 1280px,
    end: 1919.9px,
  ),
  xl: (
    begin: 1920px,
    end: 4999.99px,
  ),
) !default;

$overlapping-gt: (
  gt-xs: map-get(map-get($breakpoints, sm), begin),
  gt-sm: map-get(map-get($breakpoints, md), begin),
  gt-md: map-get(map-get($breakpoints, lg), begin),
  gt-lg: map-get(map-get($breakpoints, xl), begin),
) !default;

$overlapping-lt: (
  lt-xs: map-get(map-get($breakpoints, xs), end),
  lt-sm: map-get(map-get($breakpoints, sm), end),
  lt-md: map-get(map-get($breakpoints, md), end),
  lt-lg: map-get(map-get($breakpoints, lg), end),
  lt-xl: map-get(map-get($breakpoints, xl), end),
) !default;

@mixin match($bp) {
  @if map-has-key($breakpoints, $bp) {
    $min: map-get(map-get($breakpoints, $bp), begin);
    $max: map-get(map-get($breakpoints, $bp), end);
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if map-has-key($overlapping-gt, $bp) {
    $min: map-get($overlapping-gt, $bp);
    @media (min-width: $min) {
      @content;
    }
  } @else if map-has-key($overlapping-lt, $bp) {
    $max: map-get($overlapping-lt, $bp);
    @media (max-width: $max) {
      @content;
    }
  }
}
