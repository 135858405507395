* {
  font-family: var(--yc-font-family) !important;
}

body.hn {
  * {
    -webkit-font-smoothing: antialiased;
  }
}

h1 {
  font-size: 2rem;
  margin-top: 1em;
}

h3 {
  font-weight: 600 !important;
  font-size: 1.33333rem !important;
  border-bottom: 1px solid var(--yc-h3-border-color);
  padding-bottom: 0.5em;
}

form h3 {
  border-bottom: none;
  margin: 0;
  padding-bottom: 0;
}

h5 {
  font-weight: 600 !important;
}
