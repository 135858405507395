@use "./breakpoints" as breakpoints;

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  position: static;
  overflow-y: hidden;
  height: 100%;
  max-height: 100%;
}

html,
body {
  height: 100%;
  min-height: 100dvh;
  overscroll-behavior: none !important;
  font-size: var(--yc-font-size);
  font-weight: 300;
  background-color: var(-yc-bg);
}

body {
  margin: 0;
  padding: 0;
  color: var(--yc-text-color);
  background-color: var(--yc-bg);
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  user-select: none;
}

//
// Custom Scrollbar
//
::-webkit-scrollbar {
  width: 6px;
  // Surcharge des scrollbar par défaut
  -webkit-appearance: none;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: rgba(0, 0, 0, 0.1);
}

.spacer {
  flex: 1;
}
