@use "./breakpoints" as breakpoints;

:root {
  --yc-font-size: 12px;
  --yc-bg: #f8f9fe;
  --yc-text-color: #2f2f3b;
  --yc-text-color-lighter: #8f8f9b;
  --yc-layout-main-padding: 16px;
  --yc-font-family: "Averta", "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
  --yc-heading-color: #2f2f3b;
  --yc-card-border-radius: 15px;
  --yc-card-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}

@include breakpoints.match("gt-xs") {
  :root {
    --yc-font-size: 13px;
    --yc-layout-main-padding: 1.5rem;
  }
}

@include breakpoints.match("gt-sm") {
  :root {
    --yc-font-size: 14px;
    --yc-layout-main-padding: 1.5rem;
  }
}

@include breakpoints.match("gt-md") {
  :root {
    --yc-font-size: 15px;
    --yc-layout-main-padding: 1.75rem;
  }
}

@include breakpoints.match("gt-lg") {
  :root {
    --yc-font-size: 16px;
    --yc-layout-main-padding: 2rem;
  }
}
